.case-card {
    position: relative;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    margin-top: 5px;
    border: 1px solid #c7c7c7;
}

.card-header {
    display: flex;
    align-items: flex-start;
    padding: 10px;
}

.case-details {
    flex: 1;
    margin-left: 10px;
}

.case-title,
.case-department,
.case-number {
    margin: 0;
    padding: 0;
}

.case-title {
    font-size: 13px;
    font-weight: 400;
}

.case-department {
    font-size: 12px;
}

.case-number {
    font-size: 12px;
    color: #15aa78;
}

.ranking-section {
    position: absolute;
    top: 10px;
    right: 10px;
}

.card-body-2 {
    margin-top: 5px;
    border-top: 1px dashed #c7c7c7;
    padding: 10px;
}

.created-by,
.case-location,
.assigned-to,
.related-to {
    margin: 3px 0;
    font-size: 13px;
}
