.what-page-container {
    padding: 5px;
    font-family: 'Arial', sans-serif;
}

.services-list {
    list-style: none;
    padding: 0;
}

.service-item {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.service-label {
    display: flex;
    align-items: center;
    width: 100%;
}

.service-checkbox {
    opacity: 0;
    position: absolute;
}

.custom-checkbox {
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    margin-right: 10px;
    display: inline-block;
    border-radius: 4px;
    position: relative;
}


.service-checkbox:checked + .custom-checkbox {
    background-color: #15aa78;
    border-color: #15aa78;
}

.service-checkbox:checked + .custom-checkbox::after {
    content: '✓';
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 1;
}

.service-label > span {
    font-size: 16px;
    margin-left: 5px;
}

