.organizationchart-demo .card {
    overflow-x: auto;
    border-top: 0 none;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.p-organizationchart {
    padding: 20px;
}

.organizationchart-demo .p-organizationchart .person {
    padding: 0;
    border: 0 none;
    width: 180px;
    border-radius: 5px;
}

.organizationchart-demo .p-organizationchart .node-header, .organizationchart-demo .p-organizationchart .node-content {
    padding: .5em .7rem;
}


.organizationchart-demo .p-organizationchart .node-content {
    text-align: center;
    padding: 10px;
    border: 0 none;
}

.organizationchart-demo .p-organizationchart .node-content img {
    border: 2px solid #15aa78;
    border-radius: 50%;
}

.organizationchart-demo .leaf-node {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.organizationchart-demo .leaf-node .node-content {
    margin-bottom: 5px;
}

.node-container {
    text-align: center;
}


.horizontal-stack {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.leaf-node {
    border-radius: 10px;
    display: inline-block;
    margin-top: 3px;
}


.leaf-node-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: .7rem;
    marginLeft: 2px;
}


.organizationchart-demo .p-organizationchart .verde img {
    border: 2px solid #15aa78;
}

.organizationchart-demo .p-organizationchart .testsite img {
    border: 2px solid #15aa78;
}

.organizationchart-demo .p-organizationchart .nrel img {
    border: 2px solid steelblue;
}

.organizationchart-demo .p-organizationchart .tsmc img {
    border: 2px solid darkred;
}

.organizationchart-demo .p-organizationchart .asutempe img {
    border: 2px solid maroon;
}

.organizationchart-demo .p-organizationchart .asupolytechnic img {
    border: 2px solid maroon;
}

.organizationchart-demo .p-organizationchart .asudowntown img {
    border: 2px solid maroon;
}

.organizationchart-demo .p-organizationchart .historycoloradocenter img {
    border: 2px solid goldenrod;
}

.p-node-toggler {
    display: none !important;
}

.custom-breadcrumb {
    background-color: #ffffff !important;
    margin-top: 5px;
    border: 1px solid #f4f4f4 !important;
    border-bottom: 0px none !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.p-organizationchart-selectable-node {
    background-color: transparent !important;
}












