.popover-container {
    position: relative;
    display: inline-block;
}

.popover-content {
    position: absolute;
    z-index: 1000;
    background: transparent;
    width: 320px; /* Adjust this value to make the popover wider */
    transform: translateX(-60%) translateY(-55%);
}

.close-button {
    background: none;
    border: none;
    font-size: 1.2em;
    line-height: 1;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
